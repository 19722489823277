<template>
  <div class="d-flex">
    <CButton color="primary" variant="outline" @click.prevent="onClick">
      VEDI
    </CButton>
  </div>
</template>

<script>
export default {
  name: "OrderRoundActions",

  methods: {
    onClick() {
      // self.$store.commit("rounds/setRound", self.rounds[0]);
      const roleId = this.$store.state.role.id;
      this.$router.push({
        name: "RoleOrdersRounds",
        params: {
          id: roleId,
          roundId: this.data.id,
        },
      });
    },
  },
};
</script>
